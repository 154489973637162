import { not, pickBy, is, o } from 'ramda'
import { renderEvent } from './events'

// Constants
export const HOME = ''
export const BIO = 'bio'
export const LISTEN = 'play'
export const ABOUT = 'about'
export const RELEASE = 'release'
export const VIEWS = [ HOME, LISTEN, ABOUT, RELEASE ]

// Methods
const togglePortrait = (obj) => () => {
	obj.portrait = !obj.portrait
}
const methods = Object.freeze({ togglePortrait })

// State factory
export const createState = () => {
	return new Proxy(
		{
			view: HOME,
			lastView: HOME,
			portrait: true,
			...methods,
		},
		{
			get: function (obj, prop) {
				if (obj.hasOwnProperty(prop)) {
					const val = obj[prop]
					const isMethod = typeof val === 'function'
					// if property is a method, return closure around state object
					// else return the property
					return isMethod ? val(obj) : val
				} else if (prop === 'print') {
					// return state print function
					return () => {
						const noMethods = pickBy(o(not, is(Function)), obj)
						console.log('State:', noMethods)
					}
				} else if (prop === 'warn') {
					// return state print 'warning' function
					return () => {
						const noMethods = pickBy(o(not, is(Function)), obj)
						console.warn('State:', noMethods)
					}
				}
			},
			set: (obj, prop, value) => {
				if (obj.hasOwnProperty(prop)) {
					if (prop === 'view') {
						if (VIEWS.includes(value)) {
							obj.lastView = obj.view
							obj.view = value
							window.dispatchEvent(renderEvent)
							console.log('RENDER EVENT')
							return true
						}
					} else if (typeof obj[prop] !== 'function') {
						obj[prop] = value
						return true
					}
				}
				return false
			},
		}
	)
}

const initialState = {
	portrait: true,
	viewChanges: 0,
}

export class State {
	constructor (initialView) {
		// assign initial view
		this.view = initialView
		// assign al other initial state properties
		for (const pair of Object.entries(initialState)) {
			const [ key, value ] = pair
			this[key] = value
		}
	}

	changeView (view) {
		this.view = view
		this.viewChanges += 1
		window.dispatchEvent(renderEvent)
	}

	togglePortrait () {
		this.portrait = !this.portrait
	}

	print () {
		// const noMethods = pickBy(o(not, is(Function)), this)
		console.print('State:', this)
	}

	warn () {
		// const noMethods = pickBy(o(not, is(Function)), this)
		console.warn('State:', this)
	}
}
