import { State, VIEWS, HOME } from './state'
import { register, CLICK, RESIZE, STATE_UPDATE, HISTORY_BUTTON_PRESS } from './events'
import { changeOrientation, renderUI, nav, navArrow, updateHistory } from './reactions'
import { tail, not } from 'ramda'

let firstView

// get first view from URL
const queriedView = tail(window.location.pathname)
if (VIEWS.includes(queriedView)) {
	firstView = queriedView
}
else {
	firstView = HOME
	// set window location to HOME (origin)
	window.location = window.location.origin
}

const state = new State(firstView)

// initial UI render
renderUI(state)()

// initial orientation
changeOrientation(state)()

// event registrations
register(RESIZE, window, changeOrientation(state))
register(STATE_UPDATE, window, renderUI(state))
register(HISTORY_BUTTON_PRESS, window, () => {
	const newView = tail(window.location.pathname)
	state.changeView(newView)
})
for (const navItem of nav.children) {
	register(CLICK, navItem, (event) => {
		const el = event.currentTarget
		const newView = el.textContent
		state.changeView(newView)
		updateHistory(state)
	})
}
register(CLICK, navArrow, () => {
	state.changeView(HOME)
	updateHistory(state)
})

// debug
window.state = state
