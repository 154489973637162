import { and, either, not, tail } from "ramda";
import { ABOUT, HOME } from "./state";

// DIRTY STATE
let nameToggled = false;
const KARL = "karl";
const OSKAR = "oskar";

// Predicates

const docEl = document.documentElement;

// isPortrait :: * -> Bool
const isPortrait = () => docEl.clientWidth <= docEl.clientHeight;
// shouldPortrait :: State -> Bool
const shouldPortrait = (state) => and(isPortrait(), not(state.portrait));
// shouldLandscape :: State -> Bool
const shouldLandscape = (state) => and(not(isPortrait()), state.portrait);
// shouldReorient :: State -> Bool
const shouldReorient = either(shouldPortrait, shouldLandscape);
// shouldChangeView :: * -> Bool
const shouldChangeView = (state) => {
  const loc = tail(window.location.hash);
  return state.view !== loc;
};

// Elements

export const [main] = document.getElementsByTagName("main");
export const [title, nav, navArrow] = main.children;
export const [schulz, und, brauer] = title.getElementsByTagName("span");
export const [br] = title.getElementsByTagName("br");
export const sections = [...document.getElementsByTagName("section")];
export const background = document.getElementById("background");
export const sectionListen = sections[1];
export const sectionAbout = sections[0];
const karl = document.getElementById("karl");
const oskar = document.getElementById("oskar");
const aboutText = sectionAbout.children[0];

// Reactions

// changeView

const breakTitle = (state) => {
  if (state.portrait) {
    br.remove();
  } else {
    title.insertBefore(br, und);
  }
};

// changeOrientation :: State -> void -> void
export const changeOrientation = (state) => () => {
  // state.print()
  if (shouldReorient(state)) {
    window.requestAnimationFrame(() => {
      breakTitle(state);
      // update state
      state.togglePortrait();
      // state.warn()
    });
  }

  // RESIZE EP COVER PROPERLY
  // ep cover height hack
  // const epCover = document.getElementById('ep-cover')
  // epCover.style.height = window.getComputedStyle(epCover).width
};

// IS HIDDEN predicate
export const isHidden = (el) => el.hasAttribute("hidden");

// HIDE ELEMENT
export const hide = (el) => {
  if (!isHidden(el)) {
    el.setAttribute("hidden", "");
  }
};

/// UNHIDE / SHOW ELEMENT
export const unhide = (el) => {
  if (el.hasAttribute("hidden")) {
    el.removeAttribute("hidden");
  }
};

// UPDATE HISTORY
export const updateHistory = (state) => {
  if (state.view === HOME) {
    // if view was changed before (not counting current)
    // then previous view change pushed history state, therefore go back
    // else replace current history statec
    if (state.viewChanges > 1) {
      history.back();
    } else {
      history.replaceState({}, state.view, "/");
    }
  } else {
    // if there was a previous view (sub-URL wasnt opened directly)
    if (state.viewChanges > 0) {
      // push new history state
      history.pushState({}, state.view, `/${state.view}`);
    }
  }
};

// CHANGE VIEW
export const changeView = (state) => {
  // if view state is HOME
  if (state.view === HOME) {
    // hide all other views
    for (const view of sections) {
      hide(view);
    }
    // show normal nav
    hide(navArrow);
    unhide(nav);

    // RESET NAME TOGGLE STATE
    if (nameToggled) {
      aboutText.removeAttribute("transparent");
      background.removeAttribute("karl");
      background.removeAttribute("oskar");
      karl.removeAttribute("inverted");
      oskar.removeAttribute("inverted");
      nameToggled = false;
    }
  } else {
    // show relevant view section
    for (const view of sections) {
      if (view.attributes[0].name === state.view) {
        unhide(view);
        break;
      }
    }
    // show arrow nav
    hide(nav);
    unhide(navArrow);

    // reset about scroll sate
    if (state.view === ABOUT) {
      sectionAbout.scrollTop = 0;
    }
  }
};

const updateBackground = (state) => {
  const backgroundDiv = document.getElementById("background");
  backgroundDiv.removeAttribute(backgroundDiv.attributes[2].name);
  backgroundDiv.setAttribute(state.view || "home", "");
};

// render UI relative to state
// main reaction (side effect)
export const renderUI = (state) => () => {
  // debug print
  // state.warn()
  changeView(state);
  updateBackground(state);
};

// make release section into a "link"
const distribution_link_old =
  "https://distrokid.com/hyperfollow/schulzandbrauer/apart";
const distribution_link =
  "https://open.spotify.com/track/3ia5yiu28JnNKnV7hSnqQA?si=a1f63192bfdf41bd";
sectionListen.onclick = () => {};
// sectionListen.onclick = () => open(distribution_link);

// swap background (Karl, Oskar)
const toggleKarl = () => {
  if (background.hasAttribute("karl")) {
    background.removeAttribute("karl");
  } else {
    background.setAttribute("karl", "");
  }
};
const toggleOskar = () => {
  if (background.hasAttribute("oskar")) {
    background.removeAttribute("oskar");
  } else {
    background.setAttribute("oskar", "");
  }
};

const toggleTransparency = (el) => {
  if (el.hasAttribute("transparent")) {
    el.removeAttribute("transparent");
  } else {
    el.setAttribute("transparent", "");
  }
};
const invert = (el, secondEl) => {
  if (el.hasAttribute("inverted")) {
    el.removeAttribute("inverted");
  } else {
    el.setAttribute("inverted", "");
  }
};
