// register :: String -> Element -> (* -> *) -> UnregisterCallback
export const register = (type, target, fn) => {
	target.addEventListener(type, fn)
	return () => target.removeEventListener(type, fn)
}

export const CLICK = 'click'
export const RESIZE = 'resize'
export const HISTORY_BUTTON_PRESS = 'popstate'
export const STATE_UPDATE = 'stateupdate'

export const renderEvent = new Event(STATE_UPDATE)

// Dispatch the event.
// elem.dispatchEvent(event)
